import React from "react"
import styled from "@emotion/styled"
import Button from "./Button"
import { colors } from "../theme"

const SubNavContainer = styled.div`
  text-align: center;
`

const SubNav = styled.ul`
  list-style: none;
  padding: 0;
  margin: 40px auto;
  .nav-item-active {
    /* do things here */
  }
`

const SubNavLink = styled(Button)`
  list-style: none;
  text-align: center;

  &:after {
    background-color: ${colors.roseLight};
  }
`

const WhatToDoNav = props => {
  return (
    <SubNavContainer>
      <SubNav {...props}>
        <li>
          <SubNavLink
            to="/what-to-do/nature-and-hikes"
            activeClassName="nav-item-active"
          >
            Nature + Hikes
          </SubNavLink>
        </li>
        <li>
          <SubNavLink
            to="/what-to-do/art-and-architecture"
            activeClassName="nav-item-active"
          >
            Art + Architecture
          </SubNavLink>
        </li>
        <li>
          <SubNavLink
            to="/what-to-do/food-and-drinks"
            activeClassName="nav-item-active"
          >
            Food + Drinks
          </SubNavLink>
        </li>
      </SubNav>
    </SubNavContainer>
  )
}

export default WhatToDoNav
