import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import SEO from "../../components/SEO"
import Layout from "../../components/Layout"
import Header from "../../components/Header"
import Card from "../../components/Card"
import { smallContainer } from "../../components/container"
import WhatToDoNav from "../../components/WhatToDoNav"
import {
  ExternalLinkList,
  ExternalLink,
} from "../../components/ExternalLinkList"
import TramcarImage from "../../components/TramcarImage"
import MountainImage from "../../components/MountainImage"

// Hero Section
const hero = css`
  /* position: relative; */
`
const StyledCard = styled(Card)`
  z-index: 10;
  margin: 50px auto;
`

const CardContent = styled.div`
  position: relative;
  z-index: 100;
`

const heroHeadline = css`
  font-size: 36px;
  position: relative;
  top: -25px;
  margin-bottom: 0;
`

const tramcarImage = css`
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  width: 135px;
  mix-blend-mode: multiply;

  @media (min-width: 500px) {
    width: 160px;
  }
  @media (min-width: 940px) {
    width: 200px;
  }
  @media (min-width: 1060px) {
    width: 290px;
  }
  @media (min-width: 1150px) {
    width: 390px;
  }
  @media (min-width: 1300px) {
    width: 503px;
  }
`
const mountainImage = css`
  position: absolute;
  z-index: 11;
  bottom: 0;
  right: -20px;
  width: 500px;
  mix-blend-mode: multiply;

  @media (min-width: 570px) {
    width: 600px;
  }

  @media (min-width: 720px) {
    z-index: 11;
    right: -40px;
    width: 800px;
  }

  @media (min-width: 1000px) {
    width: 900px;
  }

  @media (min-width: 1300px) {
    width: 1052px;
  }
`

const NatureAndHikes = () => (
  <Layout>
    <SEO title="Nature + Hikes" />
    <Header />
    <div css={[smallContainer, hero]}>
      <StyledCard>
        <CardContent>
          <h1 css={heroHeadline}>Nature + Hikes</h1>
          <ExternalLinkList>
            <ExternalLink
              label="Aerial Tramway (If you have no fears of heights!)"
              href="https://pstramway.com/tram-packages/"
            />
            <ExternalLink
              label="Indian Canyons - Tahquitz Canyon"
              href="https://www.indian-canyons.com/trail_maps"
            />
            <ExternalLink
              label="The Living Desert Zoo and Garendes"
              href="https://www.livingdesert.org/visit/things-to-do/"
            />
            <ExternalLink
              label="Moorten Botanical Garden (You&rsquo;ve seen this on instagram and liked it!)"
              href="http://www.moortenbotanicalgarden.com/"
            />
            <ExternalLink label="Golfing" href="https://www.golfnow.com/" />
          </ExternalLinkList>
          <WhatToDoNav
            css={css`
              margin-top: 80px;
            `}
          />
        </CardContent>
        <div css={tramcarImage}>
          <TramcarImage />
        </div>
        <div css={mountainImage}>
          <MountainImage />
        </div>
      </StyledCard>
    </div>
  </Layout>
)

export default NatureAndHikes
