import React from "react"
import styled from "@emotion/styled"
import { colors } from "../theme"

const LinkListItem = styled.li`
  color: ${colors.roseDark};
  margin-bottom: 20px;
`

const LinkButton = styled.a`
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  color: ${colors.roseDark};
  text-decoration: underline;

  &:hover {
    color: ${colors.black};
  }
`

export const ExternalLinkList = styled.ul`
  margin: 20px 0 40px 0;
  padding: 0;
  list-style: none;
`

export const ExternalLink = ({ href, label, ...restProps }) => {
  return (
    <LinkListItem {...restProps}>
      <div>
        <LinkButton href={href} target="_blank">
          {label}
        </LinkButton>
      </div>
    </LinkListItem>
  )
}
